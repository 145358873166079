import { Container } from '../components/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import './LoginPage.css';

import { useAuth } from '../guards/AuthProvider';
import { removeToken } from '../utils/localStorage';

export const LoginPage = () => {
    removeToken();
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    let from = location.state?.from?.pathname || '/';

    const login = () => {
        auth.signin(username, password, () => {
            navigate(from, { replace: true });
        })
    };
    return (<>
        <Container>
            <div className="form-outline mb-4">
                <input type="text" id="form1Example1" className="form-control"
                       onChange={ev => setUsername(ev.target.value)}/>
                {
                    username ? <></> : <label className="form-label" htmlFor="form1Example1">Логін</label>
                }
            </div>

            <div className="form-outline mb-4">
                <input type="password" id="form1Example2" className="form-control"
                       onChange={ev => setPassword(ev.target.value)}/>
                {
                    password ? <></> : <label className="form-label" htmlFor="form1Example2">Пароль</label>
                }
            </div>

            <button className="btn btn-primary btn-block" onClick={login}>Увійти</button>
            <p className="error">
                { auth.error || ''}
            </p>
        </Container>
    </>);
};
