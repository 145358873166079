function pad(value) {
    if (value < 10) {
        return `0${value}`;
    }
    return value;
}

function getStringDateWithTime(date) {
    const day = pad(date.getDate());
    const month = pad(date.getMonth());
    const year = pad(date.getFullYear());
    const hours = pad(date.getHours());
    const min = pad(date.getMinutes());

    return `${day}.${month}.${year} ${hours}:${min}`;
}

function parseUserGroup(group) {
    const groups = {
        DROP: 'Дроп',
        RETAIL: 'Роздріб',
        WHOLESALE: 'Опт',
        ALL: 'Всім'
    };

    return groups[group] || group;
}


export {
    pad,
    getStringDateWithTime,
    parseUserGroup
}
