import { getStringDateWithTime } from '../../utils/utils';

export const NotificationItem = ({ notification, index, onClick: navigateToNotificationDetails }) => {

    const date = new Date(+notification.createdAt);
    const dateString = getStringDateWithTime(date);

    return (<tr onClick={() => navigateToNotificationDetails()}>
        <td>{index}</td>
        <td>{notification.title}</td>
        <td>{dateString}</td>
        <td>{notification.started ? 'Так' : 'Ні'}</td>
        <td>{notification.finished ? 'Так' : 'Ні'}</td>
    </tr>);
};


