import { Container } from '../components/Container';
import { UsersList } from '../components/UsersList';

export function UsersPage() {
    return (
        <Container>
            <UsersList />
        </Container>
    )
}

export default UsersPage;
