import { Container } from '../Container';
import { parseUserGroup } from '../../utils/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './NewPhone.css';
import { createPhone } from '../../api/Phones.api';

const NewPhone = (props) => {
    const [phone, setPhone] = useState(null);
    const [group, setGroup] = useState(null)

    const [error, setError] = useState('');

    const navigate = useNavigate();

    const reservePhone = async () => {
        if (!phone) {
            setError('Треба вказати телефон');
            return;
        }

        const match = phone.match(/380\d{9}/g);
        if (!match || match.length !== 1) {
            setError('Телефон має бути у форматі 380ХХХХХХХХХ (загалом 12 цифр)');
            return
        }

        if (!group) {
            setError('Треба вказати групу розсилки');
            return;
        }

        setError('')
        createPhone(phone, group)
            .then(() => {
                navigate(`/phones`);
            });
    };

    return (
        <Container>
            <p style={{ color: 'red' }}>{ error }</p>

            <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default">Номер телефону (380ХХХХХХХХХ)</span>
                <input required={phone} type="text" className="form-control" aria-label="Sizing example input"
                       aria-describedby="inputGroup-sizing-default" onChange={(v) => setPhone(v.target.value)}/>
            </div>

            <div className="radio-buttons">
                <div onClick={() => setGroup('DROP')}>
                    <input className="form-check-input" type="radio" name="radio_type" id="radio_drop"/>
                    <label className="form-check-label" htmlFor="radio_drop"
                           onClick={() => setGroup('DROP')}> {parseUserGroup('DROP')} </label>
                </div>

                <div onClick={() => setGroup('WHOLESALE')}>
                    <input className="form-check-input" type="radio" name="radio_type" id="radio_wholesale"/>
                    <label className="form-check-label" htmlFor="radio_wholesale"
                           onClick={() => setGroup('WHOLESALE')}> {parseUserGroup('WHOLESALE')} </label>
                </div>

                <div onClick={() => setGroup('RETAIL')}>
                    <input className="form-check-input" type="radio" name="radio_type" id="radio_retail"/>
                    <label className="form-check-label" htmlFor="radio_retail"
                           onClick={() => setGroup('RETAIL')}> {parseUserGroup('RETAIL')} </label>
                </div>
            </div>

            <button type="button" className="btn btn-success" onClick={reservePhone}>Створити</button>
        </Container>
    );
};

export {
    NewPhone,
};
