import './PhonesList.css';
import React, { useEffect, useState } from 'react';
import { PhoneItem } from './PhoneItem';
import { fetchPhones, removePhone, updatePhoneGroup } from '../../api/Phones.api';
import { useLocation, useNavigate } from 'react-router-dom';

const PHONES_PER_PAGE = 15;

export const PhonesList = () => {
    // Data from server
    const [phones, setPhones] = useState([]);
    const [nPhones, setNPhones] = useState(0);
    const [thereAreMore, setThereAreMore] = useState(false);

    // Local data
    const columns = ['#', 'Телефон', 'Група', 'Видалити'];
    const [page, setPage] = useState(0);
    const [refreshPageFlag, setRefreshPageFlag] = useState(false);

    const fetchPhonesCallback = ({ thereAreMore, phones, nPhones }) => {
        setThereAreMore(thereAreMore);
        setPhones(phones);
        setNPhones(nPhones);
    };

    const changePage = (delta) => {
        // Ignore spam click to left
        if (page + delta < 0) return;

        // Ignore spam click to right
        if (page + delta > page && !thereAreMore) return;

        setPage(page + delta);
    };

    useEffect(() => {
        fetchPhones(page, PHONES_PER_PAGE).then(fetchPhonesCallback);
    }, [page, refreshPageFlag]);

    useEffect(() => {
        fetchPhones(page, PHONES_PER_PAGE).then(fetchPhonesCallback);
    }, []);

    const updateGroup = (phone, group) => {
        updatePhoneGroup(phone, group)
            .then(() => {
                const _phone = phones.find(x => x.phone === phone);
                _phone.group = group;
                setRefreshPageFlag(!refreshPageFlag)
            });
    }

    const removePhoneAction = (phone) => {
        removePhone(phone)
            .then(() => {
                setPhones(phones.filter(x => x.phone !== phone));
                setRefreshPageFlag(!refreshPageFlag)
            });
    }

    const { pathname } = useLocation();

    return (
        <>

            <div className="notifications-headed-block">
                <div className="notifications-state">
                    <p className="phones-table-heading">Кількість зарезервованих телефонів у боті: {nPhones} </p>
                </div>
                <div className="buttons">
                    <a href={`${pathname}/reserve`} type="button" className="btn btn-primary">Створити</a>
                </div>
            </div>


            <table className="table">
                <thead>
                <tr>
                    {columns.map(column => <th key={column}>{column}</th>)}
                </tr>
                </thead>
                <tbody>
                {phones.map((phone, i) => <PhoneItem removePhone={removePhoneAction} updateGroup={updateGroup} phone={phone} key={phone} index={i}/>)}
                </tbody>
            </table>

            <nav aria-label="Page navigation example">
                <ul className="pagination" style={{ justifyContent: 'center' }}>
                    <li>
                        <button className="btn btn-primary" style={{ visibility: (page === 0 ? 'hidden' : 'visible') }}
                                onClick={() => changePage(-1)}>
                            <a style={{ color: 'white' }} href="#">Попередня</a>
                        </button>
                    </li>
                    <li className="page-item">
                        <a className="page-link">{page + 1}</a>
                    </li>
                    {/*If users less than %ITEMS_PER_PAGE%, that means that last page was returned*/}
                    <li>

                        <button className="btn btn-primary"
                                style={{ visibility: (thereAreMore ? 'visible' : 'hidden') }}
                                onClick={() => changePage(1)}>
                            <a style={{ color: 'white' }} href="#">Наступна</a>
                        </button>
                    </li>
                </ul>
            </nav>
        </>
    );
};
