import { Container } from '../components/Container';
import { PhonesList } from '../components/PhonesList';

export function PhonesPage() {
    return (
        <Container>
            <PhonesList />
        </Container>
    )
}

export default PhonesPage;
