import { parseUserGroup } from '../../utils/utils';

export const PhoneItem = ({ phone, index, updateGroup, removePhone }) => {
    const otherGroups = ['RETAIL', 'WHOLESALE', 'DROP'];

    return (<tr>
        <td>{index + 1}</td>
        <td>{phone.phone}</td>
        <td>
            <select onChange={(ev) => updateGroup(phone.phone, ev.target.value)} value={phone.group}>
                <option value={otherGroups[0]}>{parseUserGroup(otherGroups[0])}</option>
                <option value={otherGroups[1]}>{parseUserGroup(otherGroups[1])}</option>
                <option value={otherGroups[2]}>{parseUserGroup(otherGroups[2])}</option>
            </select>
        </td>
        <td>
            <button className="btn btn-danger" onClick={() => removePhone(phone.phone)}>Видалити</button>
        </td>
    </tr>);
};
