import { Container } from '../components/Container';
import { NotificationsList } from '../components/NotificationsList';

const NotificationsPage = () => {
    return (
        <Container>
            <NotificationsList/>
        </Container>
    )
}

export default NotificationsPage;
