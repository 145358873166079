import * as React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import UsersPage from '../pages/UsersPage';
import NotificationsPage from '../pages/NotificationsPage';
import { NotificationDetails } from '../components/NotificationDetails';

import './App.css';
import { NewNotification } from '../components/NewNotification';
import { LoginPage } from '../pages/LoginPage';

import { useAuth } from '../guards/AuthProvider';
import PhonesPage from '../pages/PhonesPage';
import { NewPhone } from '../components/NewPhone';


function Router() {
    return (
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>

                <Route path="/" element={<RequireAuth><UsersPage/></RequireAuth>}/>

                <Route path="notifications" element={<RequireAuth><NotificationsPage/></RequireAuth>}/>
                <Route path="notifications/:id" element={<RequireAuth><NotificationDetails/></RequireAuth>}/>
                <Route path="notifications/new" element={<RequireAuth><NewNotification/></RequireAuth>}/>
                <Route path="phones" element={<RequireAuth><PhonesPage/></RequireAuth>}/>
                <Route path="phones/reserve" element={<RequireAuth><NewPhone/></RequireAuth>}/>
            </Routes>
    );
}


function RequireAuth({ children }) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.token) {
        return <Navigate to="/login" state={{ from: location }} replace/>;
    }

    return children;
}

export default Router;
