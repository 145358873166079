import './Header.css';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../guards/AuthProvider';

export const Header = () => {
    const { pathname } = useLocation();

    let auth = useAuth();

    const logout = () => {
        auth.logout();
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ marginBottom: '32px' }}>
            <div className="container-fluid">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-mdb-toggle="collapse"
                    data-mdb-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fas fa-bars"></i>
                </button>
                <div className="header-flex">
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className={`nav-link ${'/' === pathname ? 'active' : ''}`} aria-current="page"
                                   href="/">Користувачі</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${'/notifications' === pathname ? 'active' : ''}`}
                                   aria-current="page" href="/notifications">Розсилки</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${'/phones' === pathname ? 'active' : ''}`}
                                   aria-current="page" href="/phones">Зарезервовані телефони</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    {
                        auth.token ? <button onClick={logout} type="button" className="btn btn-danger">Вийти</button> : <></>
                    }
                </div>
            </div>
        </nav>
    );
};


