import axios from 'axios';
import { SERVER } from '../variables';

async function fetchUsers(page = 0, perPage = 15, name, phone) {
    const nameFilter = name ? `&name=${name}` : ''
    const phoneFilter = phone ? `&phone=${phone}` : ''
    const url = `${SERVER}/customers?currentPage=${page}&perPage=${perPage}${nameFilter}${phoneFilter}`;
    const {data} = await axios.get(url);
    return data;
}

async function updateGroup(id, group) {
    const url = `${SERVER}/customers/edit-group`;
    const {data} = await axios.post(url, { ids: [id], group });
    return data;
}

async function updateCompany(id, company) {
    const url = `${SERVER}/customers/edit-company`;
    const {data} = await axios.post(url, { id, company });
    return data;
}

async function updateName(id, name) {
    const url = `${SERVER}/customers/edit-name`;
    const {data} = await axios.post(url, { id, name });
    return data;
}

async function updateEmail(id, email) {
    const url = `${SERVER}/customers/edit-email`;
    const {data} = await axios.post(url, { id, email });
    return data;
}

async function updateBanned(id, banned) {
    const url = `${SERVER}/customers/edit-banned`;
    const {data} = await axios.post(url, { id, banned });
    return data;
}
export {
    fetchUsers,
    updateGroup,
    updateCompany,
    updateName,
    updateEmail,
    updateBanned,
}
