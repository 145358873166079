import './UsersList.css';
import React, { useEffect, useState } from 'react';
import { fetchUsers, updateCompany, updateGroup } from '../../api/Users.api';
import { UserItem } from './UserItem';

const USERS_PER_PAGE = 15;

export const UsersList = () => {
    // Data from server
    const [users, setUsers] = useState([]);
    const [nUsers, setN] = useState(0);
    const [thereAreMore, setThereAreMore] = useState(false);

    // Local data
    const [page, setPage] = useState(0);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [updateUsersFlag, setUpdateUsersFlag] = useState(false);

    const columns = ['#', 'Ім\'я', 'Телефон', 'Пошта', 'Група', 'Компанія', 'Під\'єднаний', 'Статус'];

    const fetchUsersCallback = ({ thereAreMore, users, nUsers }) => {
        setThereAreMore(thereAreMore);
        setUsers(users);
        setN(nUsers);
    };

    const changePage = (delta) => {
        // Ignore spam click to left
        if (page + delta < 0) return;

        // Ignore spam click to right
        if (page + delta > page && !thereAreMore) return;

        setPage(page + delta);
    };

    const fetchWithfilter = (setFn, clearFn, value) => {
            clearFn('');
            setFn(value);
            setUpdateUsersFlag(!updateUsersFlag);
    }

    useEffect(() => {
        fetchUsers(page, USERS_PER_PAGE, name, phone).then(fetchUsersCallback);
    }, [page, updateUsersFlag]);

    useEffect(() => {
        fetchUsers(page, USERS_PER_PAGE).then(fetchUsersCallback);
    }, []);

    const updateUsersGroup = (userId, group) => {
        updateGroup(userId, group)
            .then(() => {
                const _user = users.find(x => x.id === userId);
                _user.group = group;
                setUpdateUsersFlag(!updateUsersFlag)
            });
    }

    const updateUsersCompany = (userId, company) => {
        updateCompany(userId, company)
            .then(() => {
                setUpdateUsersFlag(!updateUsersFlag)
            });
    }

    return (
        <>
            <p className="phones-table-heading">Користувачів у боті: {nUsers} </p>
            <table className="table">
                <thead>
                <tr>
                    {columns.map(column => <th key={column}>{column}</th>)}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td></td>
                    <td><input onChange={(ev) => fetchWithfilter(setName, setPhone, ev.target.value)} value={name} type="text"/></td>
                    <td><input onChange={(ev) => fetchWithfilter(setPhone, setName, ev.target.value)} value={phone} type="text"/></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {users.map((user, i) => <UserItem
                    updateGroup={updateUsersGroup}
                    updateCompany={updateUsersCompany}
                    key={user.id}
                    index={(page * USERS_PER_PAGE) + i + 1}
                    user={user}/>)}
                </tbody>
            </table>

            <nav aria-label="Page navigation example">
                <ul className="pagination" style={{ justifyContent: 'center' }}>
                    <li>
                        <button className="btn btn-primary" style={{ visibility: (page === 0 ? 'hidden' : 'visible') }}
                                onClick={() => changePage(-1)}>
                            <a style={{ color: 'white' }} href="#">Попередня</a>
                        </button>
                    </li>
                    <li className="page-item">
                        <a className="page-link">{page + 1}</a>
                    </li>
                    {/*If users less than %ITEMS_PER_PAGE%, that means that last page was returned*/}
                    <li>

                        <button className="btn btn-primary"
                                style={{ visibility: (thereAreMore ? 'visible' : 'hidden') }}
                                onClick={() => changePage(1)}>
                            <a style={{ color: 'white' }} href="#">Наступна</a>
                        </button>
                    </li>
                </ul>
            </nav>
        </>
    );
};
