import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fetchNotification } from '../../api/Notifications.api';
import { Container } from '../Container';

import './NotificationDetails.css';
import { getStringDateWithTime, parseUserGroup } from '../../utils/utils';

const ITEMS_PER_PAGE = 25;

export const NotificationDetails = () => {
// Data from server
    const [notification, setNotification] = useState([]);
    const [messages, setMessages] = useState([]);
    const [nMessages, setN] = useState([]);
    const [thereAreMore, setThereAreMore] = useState(false);

    // Local data
    const [page, setPage] = useState(0);

    const fetchNotificationCallback = ({ notification, messages, thereAreMore, nMessages }) => {
            setNotification(notification);
            setMessages(messages);
            setN(nMessages);
            setThereAreMore(thereAreMore);
    };

    const { id } = useParams();
    useEffect(() => {
        fetchNotification(id, page, ITEMS_PER_PAGE).then(fetchNotificationCallback);
    }, [page]);

    useEffect(() => {
        fetchNotification(id, page, ITEMS_PER_PAGE).then(fetchNotificationCallback);
    }, []);

    const changePage = (delta) => {
        // Ignore spam click to left
        if (page + delta < 0) return;

        // Ignore spam click to right
        if (page + delta > page && !thereAreMore) return;

        setPage(page + delta);
    };

    return (<>
        <Notification notification={notification} nMessages={nMessages}/>
        <MessagesList
            messages={messages}
            thereAreMore={thereAreMore}
            nextPage={() => changePage(1)}
            prevPage={() => changePage(-1)}
            page={page}
        />
    </>);
};

const Notification = ({ notification, nMessages }) => {
    const { title, message, started, finished, group, image } = notification;

    return (
        <Container>
            <div className="notification-details-container">
                <div className="fields">
                    <p>Назва</p>
                    <p>Повідомлення</p>
                    <p>Зображення</p>
                    <p>Була ініційована</p>
                    <p>Завершилася</p>
                    <p>Група</p>
                    <p>Кількість отримувачів</p>
                </div>
                <div className="values">
                    <p>{title}</p>
                    <p>{message}</p>
                    <p>{image ? 'Так' : 'Ні'}</p>
                    <p>{started ? 'Так' : 'Ні'}</p>
                    <p>{finished ? 'Так' : 'Ні'}</p>
                    <p>{parseUserGroup(group)}</p>
                    <p>{nMessages}</p>
                </div>
            </div>
        </Container>
    );
};

const MessagesList = (props) => {
    const { messages, thereAreMore } = props;
    const { nextPage, prevPage, page } = props;

    const columns = [
        'Ім\'я',
        'Телефон',
        'Компанія',
        'Результат',
        'Час відправки',
    ];

    return (
        <Container>
            <table className="table">
                <thead>
                <tr>
                    {columns.map(column => <th key={column}>{column}</th>)}
                </tr>
                </thead>
                <tbody>
                {messages.map(message => <MessageItem key={message.id} message={message}/>)}
                </tbody>
            </table>

            <nav aria-label="Page navigation example">
                <ul className="pagination" style={{ justifyContent: 'center' }}>
                    <li className="page-item" style={{ visibility: (page === 0 ? 'hidden' : 'visible') }}
                        onClick={() => prevPage(-1)}><a className="page-link" href="#">Попередня</a></li>
                    <li className="page-item"><a className="page-link">{page + 1}</a></li>
                    {/*If users less than %ITEMS_PER_PAGE%, that means that last page was returned*/}
                    <li className="page-item" style={{ visibility: (thereAreMore ? 'visible' : 'hidden') }}
                        onClick={() => nextPage(1)}><a className="page-link" href="#">Наступна</a></li>
                </ul>
            </nav>
        </Container>
    );
};

const MessageItem = ({ message }) => {
    const { sendAt, success, user } = message;
    const { phone, middleName, lastName, firstName, company } = user;

    const fullName = `${firstName} ${middleName} ${lastName}`;

    const date = new Date(+sendAt);
    const dateString = getStringDateWithTime(date);

    return (<tr>
        <td>{fullName}</td>
        <td>{phone}</td>
        <td>{company}</td>
        <td>{success ? 'Доставлено' : 'Не доставлено'}</td>
        <td>{dateString}</td>
    </tr>);
};
