import './NotificationsList.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationItem } from './NotificationItem';
import { fetchActiveNotification, fetchNotifications } from '../../api/Notifications.api';
import { useLocation } from 'react-router-dom';

const ITEMS_PER_PAGE = 15;
const ACTIVE_NOTIFICATION_UPDATE_TIMER = 5;

export const NotificationsList = () => {
    // Data from server
    const [notifications, setNotifications] = useState([]);
    const [nNotifications, setN] = useState(0);
    const [thereAreMore, setThereAreMore] = useState(false);

    const [activeNotification, setActiveNotification] = useState(null);
    const [activeNotificationInterval, setActiveNotificationInterval] = useState(ACTIVE_NOTIFICATION_UPDATE_TIMER);

    const [page, setPage] = useState(0);

    const columns = ['#', 'Назва', 'Дата', 'Почалася', 'Завершилася'];

    const fetchNotificationsCallback = ({ thereAreMore, notifications, nNotifications }) => {
        setThereAreMore(thereAreMore);
        setNotifications(notifications);
        setN(nNotifications);
    };

    useEffect(() => {
        fetchNotifications(page, ITEMS_PER_PAGE).then(fetchNotificationsCallback);
    }, [page]);

    useEffect(() => {
        if (activeNotificationInterval === ACTIVE_NOTIFICATION_UPDATE_TIMER) {
            fetchActiveNotification().then(({ notification }) => {
                setActiveNotification(notification);
            });
        }
    }, [activeNotificationInterval]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveNotificationInterval(activeNotificationInterval => activeNotificationInterval - 1 || ACTIVE_NOTIFICATION_UPDATE_TIMER);
        }, 1000);

        fetchNotifications(page, ITEMS_PER_PAGE).then(fetchNotificationsCallback);

        return () => clearInterval(interval);
    }, []);

    const changePage = (delta) => {
        // Ignore spam click to left
        if (page + delta < 0) return;

        // Ignore spam click to right
        if (page + delta > page && !thereAreMore) return;

        setPage(page + delta);
    };

    let navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <>
            <div className="notifications-headed-block">
                <div className="notifications-state">
                    <p className="table-heading">Розсилок загалом: {nNotifications} </p>
                    <p className="table-heading">{
                        activeNotification ?
                            <span>{'Наразі розсилається ' + activeNotification}</span> : 'Немає активної розсилки'
                    } (Оновлення через {activeNotificationInterval})</p>
                </div>
                <div className="buttons">
                    <a href={`${pathname}/new`} type="button" className="btn btn-primary">Створити</a>
                </div>
            </div>
            <table className="table">
                <thead>
                <tr>
                    {columns.map(column => <th key={column}>{column}</th>)}
                </tr>
                </thead>
                <tbody>
                {notifications.map((notification, i) => <NotificationItem key={notification.id}
                                                                          onClick={() => navigate(`${pathname}/${notification.id}`)}
                                                                          index={(page * ITEMS_PER_PAGE) + i + 1}
                                                                          notification={notification}/>)}
                </tbody>
            </table>


            <nav aria-label="Page navigation example">
                <ul className="pagination" style={{ justifyContent: 'center' }}>
                    <li>
                        <button className="btn btn-primary" style={{ visibility: (page === 0 ? 'hidden' : 'visible') }}
                                onClick={() => changePage(-1)}>
                            <a style={{ color: 'white' }} href="#">Попередня</a>
                        </button>
                    </li>
                    <li className="page-item">
                        <a className="page-link">{page + 1}</a>
                    </li>
                    {/*If users less than %ITEMS_PER_PAGE%, that means that last page was returned*/}
                    <li>

                        <button className="btn btn-primary"
                                style={{ visibility: (thereAreMore ? 'visible' : 'hidden') }}
                                onClick={() => changePage(1)}>
                            <a style={{ color: 'white' }} href="#">Наступна</a>
                        </button>
                    </li>
                </ul>
            </nav>
        </>
    );
};
