import './index.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";


import App from './app/App';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
