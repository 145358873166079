import { useState } from 'react';
import React from 'react';
import { login } from '../api/Admin.api';
import { createContext, useContext } from 'react';
import { getToken, removeToken, storeToken } from '../utils/localStorage';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

function useAuth() {
    return useContext(AuthContext);
}

function AuthProvider({ children }) {
    let navigate = useNavigate();
    let [token, setToken] = useState(getToken());
    let [error, setError] = useState(getToken());


    let signin = (username, password, callback) => {
        return login(username, password)
            .then((token) => {
                storeToken(token);
                setToken(token);
                callback();
            })
            .catch((err) => {
                setError('Неправильний логін або пароль');
            });
    };

    const logout = () => {
        removeToken();
        setToken(null);
        navigate('/login');
    };

    let value = { token, signin, logout, error };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export {
    AuthProvider,
    AuthContext,
    useAuth
};
