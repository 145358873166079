import { parseUserGroup } from '../../utils/utils';
import { updateName, updateEmail, updateBanned } from '../../api/Users.api';
import { useState } from 'react';

export const UserItem = (arg) => {
    const { user, index, updateGroup, updateCompany } = arg;
    const otherGroups = ['RETAIL', 'WHOLESALE', 'DROP'];

    const [name, changeName] = useState(`${user.firstName} ${user.middleName} ${user.lastName}`);
    const [email, changeEmail] = useState(user.email);
    const [banned, changeBanned] = useState(user.banned);

    const updateTheName = async (id, name) => {
        await updateName(id, name);
        changeName(name);
    }

    const updateTheEmail = async (id, email) => {
        await updateEmail(id, email);
        changeEmail(email);
    }

    const updateTheBanned = async (id, banned) => {
        await updateBanned(id, banned);
        changeBanned(banned);
    }

    return (<tr style={{ alignItems: 'center'}}>
        <td>{index}</td>
        <td><input onChange={async (ev) => {
            const { value } = ev.target;
            await updateTheName(user.id, value);
        }} value={name} type="text"/></td>
        <td>{ user.phone}</td>
        <td>
            <input onChange={async (ev) => {
                const { value } = ev.target;
                await updateTheEmail(user.id, value);
            }} value={email} type="text"/>
        </td>
        <td>
            <select onChange={(ev) => updateGroup(user.id, ev.target.value)} value={user.group}>
                <option value={otherGroups[0]}>{parseUserGroup(otherGroups[0])}</option>
                <option value={otherGroups[1]}>{parseUserGroup(otherGroups[1])}</option>
                <option value={otherGroups[2]}>{parseUserGroup(otherGroups[2])}</option>
            </select>
        </td>
        <td>
            <input onChange={(ev) => updateCompany(user.id, ev.target.value)} value={user.company || ''} type="text"/>
        </td>
        <td>{user.quit ? 'Hi' : 'Так'}</td>
        <td>
            { banned ?
                <>
                    <button className="btn btn-success" onClick={() => updateTheBanned(user.id, false)}>
                        Розблокувати
                    </button>
                </> :
                <>

                    <button className="btn btn-danger" onClick={() => updateTheBanned(user.id, true)}>
                        Заблокувати
                    </button></>
            }
        </td>
    </tr>);
};
