import axios from 'axios';
import { SERVER } from '../variables';

async function fetchPhones(page = 0, perPage = 15) {
    const url = `${SERVER}/reserved?currentPage=${page}&perPage=${perPage}`;
    const {data} = await axios.get(url);
    return data;
}

async function updatePhoneGroup(phone, group) {
    const url = `${SERVER}/reserved/update`;
    const {data} = await axios.post(url, { phone, group });
    return data;
}

async function removePhone(phone) {
    const url = `${SERVER}/reserved?phone=${phone}`;
    const {data} = await axios.delete(url);
    return data;
}

async function createPhone(phone, group) {
    const url = `${SERVER}/reserved`;
    const {data} = await axios.post(url, { phone, group });
    return data;
}

export {
    fetchPhones,
    updatePhoneGroup,
    removePhone,
    createPhone
}
