import axios from 'axios';
import { SERVER } from '../variables';

async function fetchNotifications(page = 0, perPage = 15) {
    const url = `${SERVER}/notifications?currentPage=${page}&perPage=${perPage}`;
    const { data } = await axios.get(url);
    return data;
}

async function fetchNotification(id, page = 0, perPage = 15) {
    const url = `${SERVER}/notifications/${id}?currentPage=${page}&perPage=${perPage}`;
    const { data } = await axios.get(url);
    return data;
}

async function fetchActiveNotification() {
    const url = `${SERVER}/notifications/active`;
    const { data } = await axios.get(url);
    return data;
}

async function createNotification(title, message, group, file) {
    const url = `${SERVER}/notifications`;

    const body = new FormData();
    body.append('title', title);
    body.append('message', message);
    body.append('group', group);

    if (file) {
        body.append('file', file);
    }

    const { data } = await axios.post(url, body);
    return data;
}

export {
    fetchNotifications,
    fetchNotification,
    createNotification,
    fetchActiveNotification,
};
