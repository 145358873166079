import * as React from 'react';
import { Header } from '../components/Header';

import './App.css';
import Router from './Router';
import { AuthProvider } from '../guards/AuthProvider';

function App() {
    return (
        <div className="App">
            <AuthProvider>
                <Header/>
                <Router/>
            </AuthProvider>
        </div>
    );
}

export default App;
