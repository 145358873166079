import { Container } from '../Container';
import { parseUserGroup } from '../../utils/utils';
import { useState } from 'react';
import { createNotification } from '../../api/Notifications.api';
import { useNavigate } from 'react-router-dom';

import './NewNotification.css';

const NewNotification = (props) => {
    const [title, setTitle] = useState(null);
    const [message, setMessage] = useState(null);
    const [file, setFile] = useState(null);
    const [group, setGroup] = useState(null)

    const [error, setError] = useState('');

    const navigate = useNavigate();

    const sendFile = async () => {
        if (!title) {
            setError('Треба вказати назву розсилки');
            return;
        }

        if (!message) {
            setError('Треба вказати повідомлення розсилки');
            return;
        }

        if (!group) {
            setError('Треба вказати групу розсилки');
            return;
        }

        setError('')
        createNotification(title, message, group, file)
            .then(({ id }) => {
                navigate(`/notifications/${id}`);
            });
    };

    return (
        <Container>
            <p style={{ color: 'red' }}>{ error }</p>

            <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default">Назва розсилки</span>
                <input required={title} type="text" className="form-control" aria-label="Sizing example input"
                       aria-describedby="inputGroup-sizing-default" onChange={(v) => setTitle(v.target.value)}/>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default">Текстове повідомлення</span>
                <input type="text" className="form-control" aria-label="Sizing example input"
                       aria-describedby="inputGroup-sizing-default" onChange={(v) => setMessage(v.target.value)}/>
            </div>


            <div>
                <label className="form-label" htmlFor="customFile">Зображення (необов'язково)</label>
                <input type="file" className="form-control" id="customFile" onChange={({ target: { files } }) => {
                    setFile(files[0]);
                }}/>
            </div>

            <div className="radio-buttons">
                <div onClick={() => setGroup('DROP')}>
                    <input className="form-check-input" type="radio" name="radio_type" id="radio_drop"/>
                    <label className="form-check-label" htmlFor="radio_drop"
                           onClick={() => setGroup('DROP')}> {parseUserGroup('DROP')} </label>
                </div>

                <div onClick={() => setGroup('WHOLESALE')}>
                    <input className="form-check-input" type="radio" name="radio_type" id="radio_wholesale"/>
                    <label className="form-check-label" htmlFor="radio_wholesale"
                           onClick={() => setGroup('WHOLESALE')}> {parseUserGroup('WHOLESALE')} </label>
                </div>

                <div onClick={() => setGroup('RETAIL')}>
                    <input className="form-check-input" type="radio" name="radio_type" id="radio_retail"/>
                    <label className="form-check-label" htmlFor="radio_retail"
                           onClick={() => setGroup('RETAIL')}> {parseUserGroup('RETAIL')} </label>
                </div>

                <div onClick={() => setGroup('ALL')}>
                    <input className="form-check-input" type="radio" name="radio_type" id="radio_all"/>
                    <label className="form-check-label" htmlFor="radio_all"
                           onClick={() => setGroup('ALL')}> {parseUserGroup('ALL')} </label>
                </div>
            </div>

            <button type="button" className="btn btn-success" onClick={sendFile}>Створити</button>
        </Container>
    );
};

export {
    NewNotification,
};
